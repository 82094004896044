import React from "react";
import Slider from "react-slick";
import { graphql } from 'gatsby';
import Gallery1 from '../../static/images/gallery-1.webp'
import Gallery2 from '../../static/images/gallery-2.webp'
import Gallery3 from '../../static/images/gallery-3.png'
import Gallery4 from '../../static/images/gallery-4.png'
import Gallery5 from '../../static/images/gallery-5.png'
import Gallery6 from '../../static/images/gallery-6.png'
import Gallery7 from '../../static/images/gallery-7.png'
import './ImageSlider.scss';


class ImageSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <Slider {...settings}>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery1} />
                </div>
              </figure>
          </div>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery6} />
                </div>
              </figure>
          </div>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery2} />
                </div>
              </figure>
          </div>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery7} />
                </div>
              </figure>
          </div>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery3} />
                </div>
              </figure>
          </div>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery4} />
                </div>
              </figure>
          </div>
          <div className="slider">
              <figure className="slider-figure">
                <div className="slider-figure-img">
                  <img src={Gallery5} />
                </div>
              </figure>
          </div>
      </Slider>
    );
  }
}

export default ImageSlider;